/***************************/
/* HEADER */
/***************************/

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8rem;
  width: 100%;
  padding: 0 4.8rem;
  margin-top: 1rem;
  z-index: 999;
}

.sticky .header {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 8rem;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  background-color: rgba(255, 255, 255, 0.97);
  z-index: 999;
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.03);
}

.header-logo:link,
.header-logo:visited {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 4.8rem;
  /*border-radius: 17.5438596%; /* iOS icon border radius */
}

.wordmark {
  text-decoration: none;
  font-family: 'Signika';
  font-weight: 400;
  font-size: 37px;
  letter-spacing: 0.5px;
  color: var(--gray-dark);
}

.nav-list {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 3.2rem;
}

/* for Safari versions that don't support flexbox */
.no-flexbox-gap .nav-list li:not(:last-child) {
  margin-right: 3.2rem;
}

.nav-link:link,
.nav-link:visited {
  text-decoration: none;
  color: var(--gray-dark);
  font-size: 1.728rem;
  font-weight: 600;
  padding: 1.2rem 0;
  cursor: pointer;
  font-family: inherit;
  transition: all 0.3s;
}

/***************************/
/* HERO */
/***************************/

.section-hero {
  padding: 5.6rem 0;
  position: relative;
  background: linear-gradient(to top left, #b3eddd, #e6f9f4);
  border-radius: 15px;
  margin: 1rem 2rem;
}

.section-hero-sticky {
  /* header height (8rem) + header vertical margin (1rem) + hero default top padding (5.6rem) = 14.6rem */
  padding-top: 14.6rem;
}

.hero-container {
  max-width: 130rem;
  padding: 0 4.8rem;
  margin: 0 auto;
  display: grid;
  column-gap: 6.4rem;
  row-gap: 17.6rem;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}

.hero-body-text {
  font-size: 2.074rem;
  line-height: 1.4;
  padding-right: 3.2rem;
  margin-bottom: 4.8rem;
}

.hero-img {
  display: block;
  width: 67%;
  margin: 0 auto;
  transition: all 0.5s;
}

.your-circle {
  display: inline-block;
  position: relative;
}

.hero-underline {
  position: absolute;
  right: 0;
  bottom: -22%;
  width: 100%;
}

.hero-learn-more {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

/* for Safari versions that don't support flexbox */
.no-flexbox-gap .hero-learn-more span {
  margin-right: 1.2rem;
}

/***************************/
/* VISION */
/***************************/

.section-vision {
  padding: 12.8rem 0;
}

.vision-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  column-gap: 8rem;
  row-gap: 17.6rem;
  position: relative;
}

.vision-row-highlight {
  position: absolute;
  grid-row: 3 / 4;
  grid-column: 1 / -1;
  height: 150%;
  width: 102%;
  border-radius: 15px;
  background: linear-gradient(to top left, #ccf3e8, #e6f9f4);
  justify-self: center;
  align-self: center;
  z-index: -99;
}

.vision-accent-bar {
  height: 0.75rem;
  width: 6rem;
  background-color: var(--bg-color-main);
  margin-bottom: 2.4rem;
}

.vision-svg-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vision-svg {
  width: 90%;
}

.vision-text-box {
  padding: 0 2.4rem;
  grid-column: 2 / -1;
}

.vision-text {
  font-size: 2.074rem;
  line-height: 1.4;
}

/***************************/
/* CTA */
/***************************/

.section-cta {
  padding: 9.6rem 0;
}

.cta {
  display: grid;
  grid-template-columns: 1.25fr 1fr;
  background: linear-gradient(45deg, var(--bg-color-main), RGB(0, 229, 165));
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  overflow: hidden;
}

.cta-text-box {
  padding: 6.4rem;
}

.cta-form {
  display: grid;
  grid-template-columns: 1fr 5fr;
  row-gap: 3.2rem;
  align-items: center;
}

.cta-text {
  font-size: 2.074rem;
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 6.4rem;
}

.cta-form-label {
  display: block;
  font-size: 1.728rem;
  font-weight: 600;
  padding: 1.2rem 0;
}

.cta-form-input {
  width: 100%;
  font-size: 1.728rem;
  font-weight: 600;
  font-family: inherit;
  color: inherit;
  border: none;
  padding: 1.2rem;
  border-radius: 9px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.cta-form-input::placeholder {
  color: #aaa;
}

.form-btn {
  grid-column: 1 / -1;
  text-align: center;
}

.cta-img-box {
  background-image: url('../img/cta-img.webp');
  background-size: cover;
  background-position: center;
}

/***************************/
/* FOOTER */
/***************************/

.footer {
  padding: 9.6rem 0 6.4rem 0;
}

.footer-list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 3.2rem;
  list-style: none;
}

/* for Safari versions that don't support flexbox */
.no-flexbox-gap .footer-list li:not(:last-child) {
  margin-right: 3.2rem;
}

.footer-list li {
  color: var(--gray-dark);
  font-size: 1.44rem;
  font-weight: 500;
}

.footer-contact-us-flex {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

/* for Safari versions that don't support flexbox */
.no-flexbox-gap .footer-contact-us-flex a:not(:last-child) {
  margin-right: 0.8rem;
}

.footer-icon {
  height: 2.4rem;
  width: 2.4rem;
}

.footer-link:link,
.footer-link:visited {
  text-decoration: none;
  color: inherit;
}

.footer-link:hover,
.footer-link:active {
  color: #111;
}

/***************************/
/* TERMS AND PRIVACY */
/***************************/

.terms-header {
  padding: 4rem 0;
  position: relative;
  background: linear-gradient(to right, #b3eddd, #e6f9f4);
  border-radius: 15px;
  margin: 1rem 2rem 6.4rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.terms-title {
  margin: 0;
  font-family: 'Lora', 'Open Sans', sans-serif;
  font-weight: 700;
  letter-spacing: -0.5px;
  font-size: 3.583rem;
  line-height: 1.2;
}

.terms-container {
  max-width: 75rem;
  margin: 0 auto;
  padding: 2.4rem;
  font-size: 1.728rem;
  line-height: 1.6;
}

.terms-section-title {
  margin-bottom: 2.4rem;
  font-size: 2.488rem;
}

.terms-container p {
  margin-bottom: 2.4rem;
}

.terms-list {
  list-style-position: outside;
  padding-left: 2rem;
  margin: 0;
}

.terms-list li {
  margin-bottom: 2.4rem;
  padding-left: 1.2rem;
}

.terms-list-roman {
  list-style-type: lower-roman;
}

.terms-list-alpha {
  list-style-type: lower-latin;
}

/***************************/
/* BETA */
/***************************/

.beta-hero-container {
  max-width: 130rem;
  padding: 0 4.8rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.beta-hero-body {
  display: grid;
  column-gap: 7rem;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
}

.beta-svg {
  width: 90%;
  grid-row: 1 / 3;
}

.beta-hero-body-text {
  font-size: 2.074rem;
  line-height: 1.4;
  padding-right: 3.2rem;
  margin-bottom: 4.8rem;
  grid-column: -3 / -1;
}

.beta-platform-button {
  justify-content: center;
}

.section-os {
  padding: 12.8rem 4.8rem;
  max-width: 130rem;
  margin: 0 auto;
  align-items: center;
}

.os-container {
  display: grid;
  grid-template-columns: 1fr 6fr;
  row-gap: 8rem;
  align-items: flex-start;
}

.beta-margin-top {
  margin-top: 1.49rem;
}

.beta-margin-bottom {
  margin-bottom: 1.4rem;
}

.app-store-badge {
  height: 9.6rem;
}
